
import ApiService from "@/core/services/ApiService";
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useBus } from "../../../../bus";
import { Field } from "vee-validate";
import { VueCookieNext } from 'vue-cookie-next';
import { DrawerComponent } from "@/assets/ts/components/_DrawerComponent";

export default defineComponent({
  name: "kt-drawer-room-institute",
  components: { Field },

  data() {
    return {
      load: false,
      institute_info_id: "",
      room_num: "",
      room_location: "",
      room_capacity: "",
      room_equipment: "",

      loading: false,
    };
  },
  async created() {
    this.emitter.on("entity-add-room-data", async (id) => {
      this.institute_info_id = id;
      // api
    });
  },
  methods: {
    async formSubmit() {
      let user_id = VueCookieNext.getCookie('_seip_user');
      let data = {
        room_no: this.room_num,
        room_location: this.room_location,
        room_capacity: this.room_capacity,
        room_equipment: this.room_equipment,
        institute_info_id: this.institute_info_id,
        user_id: user_id.id,
      };

      this.loading = true;
      await ApiService.post("institute/detail/save", data)
        .then((response) => {
          DrawerComponent?.hideAll();
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit("room-updated", true);
            Swal.fire({
              title: "Success!",
              text: response.data.data,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-success",
              },
            }).then(() => {
              this.room_num = "";
              this.room_location = "";
              this.room_capacity = "";
              this.room_equipment = "";
              this.institute_info_id = "";
            });
          } else {
            let err = "";
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + "<br>";
            }
            Swal.fire({
              title: "Please check all the required field",
              html: err,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Close",
              customClass: {
                confirmButton: "btn btn-danger",
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: "Unknown error",
            html: response.data.error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Close",
            customClass: {
              confirmButton: "btn btn-danger",
            },
          });
          console.log(response);
        });
    },
  },
});
