
import ApiService from '@/core/services/ApiService';
import { defineComponent, ref } from 'vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { useBus } from '../../../../bus';
import { Field } from 'vee-validate';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { VueCookieNext } from 'vue-cookie-next';

export default defineComponent({
  name: 'kt-drawer-mapCourse-institute',
  components: { Field },

  data() {
    return {
      tranche: [],
      association: [],
      course: [],
      load: false,
      tranche_id: '',
      entity_info_id: '',
      course_info_id: '',
      institute_info_id: '',

      loading: false,
    };
  },
  async created() {
    await this.getTranche();
    await this.associationList();
    this.emitter.on('entity-add-mapCourse-data', async (id) => {
      this.institute_info_id = id;
      // api
    });
  },
  methods: {
    async getTranche() {
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranche = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async associationList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.association = response.data.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async courseSelect() {
      await ApiService.get(
        'course/list?tranche=' +
          this.tranche_id +
          '&entity_id=' +
          this.entity_info_id
      )
        .then((response) => {
          this.course = response.data.data;
          console.log(this.course);
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async formSubmit() {
      let user_id = VueCookieNext.getCookie('_seip_user');
      let data = {
        tranche_id: this.tranche_id,
        entity_info_id: this.entity_info_id,
        institute_info_id: this.institute_info_id,
        course_info_id: this.course_info_id,
        user_id: user_id.id
      };

      this.loading = true;
      await ApiService.post('institute/detail/map_course', data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.emitter.emit('courseMap-updated', true);
            // if(response.status="error")
            Swal.fire({
              title: 'Success!',
              text: response.data.data,
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            }).then(() => {
              DrawerComponent?.hideAll();
              (this.tranche_id = ''),
                (this.entity_info_id = ''),
                (this.course_info_id = ''),
                (this.institute_info_id = '');
            });
          } else {
            let err = '';
            for (const field of Object.keys(response.data.errors)) {
              err += response.data.errors[field][0] + '<br>';
            }
            Swal.fire({
              title: 'Please check all the required field',
              html: err,
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: 'Close',
              customClass: {
                confirmButton: 'btn btn-danger',
              },
            });
          }
        })
        .catch(({ response }) => {
          this.loading = false;
          Swal.fire({
            title: 'Unknown error',
            html: response.data.error,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Close',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          });
          console.log(response);
        });
    },
  },
});
